import React from 'react'

import Layout from '../layouts/fr'
import Home from '../components/Home'

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default IndexPage
