import React from 'react'

import { messages } from '../lang'
import Layout from './index.jsx'

export default (props) => (
  <Layout
    i18nMessages={messages.fr}
    {...props}
  />
)
